let awsRegion = 'us-east-2';

export default {
 api_urls: {
   reporting: 'https://jxh3uzjl7h.execute-api.us-east-2.amazonaws.com/api',
   geojson: 'https://fqk7y0q18c.execute-api.us-east-2.amazonaws.com/api',
   overlays: 'https://55pxryaz01.execute-api.us-east-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.staging.vale.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.vale.forwoodsafety.com?redirect_uri=https://geoeditor.staging.vale.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.vale.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-2_uNxxRpKGT',
   userPoolWebClientId: '2amup6iu3hdb4c88ubal3r2s86',
   cookieStorage: {
     domain: '.staging.vale.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
